<template>
  <div class="flex flex-col gap-2 sm:gap-4 min-h-screen">
    <div class="flex items-center justify-between gap-4">
      <h3 class="font-semibold text-lg md:text-xl">All Items</h3>
      <easiPaginAsync
        infoOnly
        leftAlign
        :totalRecords="totalRecords"
        v-model:rowsPerPage="paginationPayload.limit"
        v-model:page="paginationPayload.page"
      />
    </div>

    <easiPlainTable
      v-if="visibleData.length"
      :data="visibleData"
      :headers="tableHeaders"
      :loading="loading"
      :onRowClick="handleRowClick"
      v-model:selected="selectedData"
    >
      <template #name="slotsProp">
        <div class="flex items-center gap-2 whitespace-nowrap">
          <div
            class="w-9 h-9 flex-shrink-0 overflow-hidden rounded-full bg-highlight flex items-center justify-center"
          >
            <img
              v-if="slotsProp.data.image"
              :src="slotsProp.data.image"
              class="h-full w-full object-cover"
              alt=""
            />
            <div v-else class="font-bold capitalize">
              {{ initials(null, slotsProp.data.name) }}
            </div>
          </div>
          <span>{{ slotsProp.data.name }}</span>
        </div>
      </template>

      <template #date="slotsProp">
        <div class="flex items-center justify-between gap-2">
          <span>{{ formatDate(slotsProp.data.createdAt, "DD/MM/YYYY") }}</span>
          <easiPopover :data="slotsProp.data" :options="dropdownOptions">
            <i class="pi pi-ellipsis-v text-dark-600" />
          </easiPopover>
        </div>
      </template>
    </easiPlainTable>
    <div v-else class="text-center">No Inventory Item to show</div>

    <easiPaginAsync
      :totalRecords="totalRecords"
      v-model:rowsPerPage="paginationPayload.limit"
      v-model:page="paginationPayload.page"
    />
  </div>

  <!-- //////  Delete Modal ////// -->
  <easiAlert
    :icon="opsIcon"
    v-if="deleteModal"
    :title="`<span class='text-xl'>Are you sure you want to delete <br/> this item?</span>`"
  >
    <div class="pb-4 px-6 grid grid-cols-2 gap-4">
      <easiButton
        variant="outlined"
        size="medium"
        @click="deleteModal = false"
        block
        >Cancel</easiButton
      >
      <easiButton
        size="medium"
        :loading="deleteLoading"
        @click="handleDelete"
        block
        >Yes</easiButton
      >
    </div>
  </easiAlert>

  <!-- //////  Alert Modal ////// -->

  <easiAlert v-if="isAlert" :caption="alertMessage">
    <div class="pb-4 px-6">
      <easiButton
        size="medium"
        :loading="loading"
        @click="isAlert = false"
        block
        >Okay!</easiButton
      >
    </div>
  </easiAlert>
</template>

<script setup>
import opsIcon from "@/assets/icons/empty-state.svg";

import { computed, reactive, ref, watch } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { useRouter } from "vue-router";
import {
  getInventoryItems,
  deleteInventoryItem,
} from "@/services/book-keeping/inventory";

const { formatAmount, formatDate, initials } = helperFunctions;
const store = useDataStore();
const router = useRouter();

const props = defineProps({
  loading: Boolean,
});
const emit = defineEmits(["update:loading"]);

const alertMessage = ref("");
const isAlert = ref(false);

const deleteLoading = ref(false);
const deleteModal = ref(false);
const selectedItem = ref(null);

const selectedData = ref([]);

const totalRecords = computed(() =>
  store.getBookingInventory
    ? store.getBookingInventory.paginationData.totalRecords
    : 0
);
const paginationPayload = reactive({
  limit: 20,
  page: 1,
});

watch(paginationPayload, () => {
  queryInventories();
});

const visibleData = computed(() =>
  store.getBookingInventory ? store.getBookingInventory.docs : []
);
console.log("Inventory Items", visibleData.value);

const tableHeaders = [
  {
    style: ``,
    title: "Item Name",
    field: "name",
    type: "name",
  },
  {
    style: ``,
    title: "Description",
    field: "description",
  },
  {
    style: ``,
    title: "No. of items sold",
    field: "unitsSold",
  },
  {
    style: ``,
    title: "Unit Price",
    field: "sellingPrice",
    format: (val) => {
      return val ? formatAmount(val) : "--";
    },
  },
  {
    style: ``,
    preventRowClick: true,
    title: "Date Created",
    field: "createdAt",
    type: "date",
  },
];

const dropdownOptions = [
  {
    title: "View item",
    action: (val) => {
      router.push({ name: "ViewInventory", params: { id: val._id } });
    },
  },
  {
    title: "Edit",
    action: (val) => {
      router.push({
        name: "ViewInventory",
        params: { id: val._id },
        query: { edit: "true" },
      });
    },
  },
  {
    title: "Delete",
    action: (val) => {
      selectedItem.value = val;
      deleteModal.value = true;
    },
  },
];

const handleRowClick = (e) => {
  router.push({ name: "ViewInventory", params: { id: e._id } });
};

const handleDelete = async () => {
  if (!selectedItem.value) return;

  try {
    deleteLoading.value = true;
    const res = await deleteInventoryItem(selectedItem.value._id);
    if (res.success) {
      deleteModal.value = false;
      alertMessage.value = "Item successfully deleted";
      isAlert.value = true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    console.log(error);
  } finally {
    deleteLoading.value = false;
  }
};

const queryInventories = async () => {
  try {
    emit("update:loading", true);
    await getInventoryItems(paginationPayload);
  } catch (error) {
    console.log(error);
  } finally {
    emit("update:loading", false);
  }
};
</script>

<style></style>
