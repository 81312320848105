import { useDataStore } from "@/stores/data.js";
const store = useDataStore();
const { query, mutate } = store;
//// QUERIES /////

const service = "BOOKKEEPING";

export const getCategories = async () => {
  try {
    const res = await query({
      endpoint: "GetCategories",
      service,
    });

    if (res.success) {
      store.$patch({
        bookkeepingCategories: res.data,
      });
    }

    console.log(res);
  } catch (error) {
    throw error;
  }
};

//// MUTATIONS /////

export const addCategory = async (name) => {
  const input = {
    name,
  };

  try {
    const res = await mutate({
      endpoint: "CreateCategory",
      data: { input },
      service,
    });

    if (res.success) {
      getCategories();
    }

    return res;
  } catch (error) {
    throw error;
  }
};

export const updateCategory = async ({ id, name }) => {
  const data = {
    updateCategoryId: id,
    input: {
      name: name,
    },
  };

  try {
    const res = await mutate({
      endpoint: "UpdateCategory",
      data,
      service,
    });

    if (res.success) {
      getCategories();
    }

    return res;
  } catch (error) {
    throw error;
  }
};

export const deleteCategory = async (deleteCategoryId = null) => {
  try {
    const res = await mutate({
      endpoint: "DeleteCategory",
      data: { deleteCategoryId },
      service,
    });

    if (res.success) {
      getCategories();
    }

    return res;
  } catch (error) {
    throw error;
  }
};
