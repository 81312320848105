import { useDataStore } from "@/stores/data.js";
const store = useDataStore();
const { query, mutate } = store;
//// QUERIES /////

const service = "BOOKKEEPING";

export const getInventoryItems = async (pagination, archived = null) => {
  const payload = {
    paging: {
      filter: {},
      limit: 20,
      page: 1,
      search: null,
      sort: {},
      ...pagination,
    },
    archived,
  };
  try {
    const res = await query({
      endpoint: "GetAllBookKeepingInventory",
      payload,
      service,
    });

    if (res.success) {
      store.$patch({
        bookkeepingInventory: res.data,
      });
    }

    console.log(res);
  } catch (error) {
    throw error;
  }
};

export const getInventoryStats = async () => {
  try {
    const res = await query({
      endpoint: "GetBookKeepingInventoryData",
      service,
    });

    if (res.success) {
      store.$patch({
        bookkeepingInventoryStats: res.data,
      });
    }

    console.log(res);
  } catch (error) {
    throw error;
  }
};

//// MUTATIONS /////

export const addInventoryItem = async (payload, bulk = false) => {
  const endpoint = bulk
    ? "CreateBookKeepingInventoryBulk"
    : "CreateBookKeepingInventory";
  try {
    const res = await mutate({
      endpoint,
      data: { input: payload },
      service,
    });

    if (res.success) {
      getInventoryItems();
    }

    return res;
  } catch (error) {
    throw error;
  }
};

export const updateInventoryItem = async (id, payload) => {
  const data = {
    updateBookKeepingInventoryId: id,
    input: payload,
  };
  try {
    const res = await mutate({
      endpoint: "UpdateBookKeepingInventory",
      data,
      service,
    });

    if (res.success) {
      getInventoryItems();
    }

    return res;
  } catch (error) {
    throw error;
  }
};

export const deleteInventoryItem = async (id) => {
  try {
    const res = await mutate({
      endpoint: "DeleteBookKeepingInventory",
      data: { deleteBookKeepingInventoryId: id },
      service,
    });

    if (res.success) {
      getInventoryItems();
    }

    return res;
  } catch (error) {
    throw error;
  }
};
